import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";
import Link, { LinkProps } from "next/link";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  children?: React.ReactNode;
};

const CheckboxBody: React.FC<CheckboxProps> = ({ children, ...inputProps }) => (
  <>
    <input
      className="w-5 h-5 min-w-5 focus:outline-none focus:shadow-outline accent-default mr-sm"
      type="checkbox"
      // without `readonly` set, it'll throw an error in jest, but this doesn't do anything for checkboxes.
      // checking the checkbox modifies the field's state, not value; so `readonly` doesn't apply.
      readOnly
      {...inputProps}
    />
    <label
      // if an element is used, it doesn't get this line height so the [*>&] enforces it for all children
      // you can override it with ![&>span]:leading-[1.5rem] for example if you're adding a span
      className="leading-[1.25rem] [&>*]:leading-[1.25rem]"
      htmlFor={inputProps.id}
    >
      {children}
    </label>
  </>
);

type Props = CheckboxProps & {
  labelOrder?: "before" | "after";
  href?: LinkProps["href"];
};

const Checkbox: React.FC<Props> = ({
  labelOrder = "after",
  href,
  className = "",
  ...inputProps
}) => {
  const classes = classNames(
    className,
    "text-sm w-full min-h-8 flex items-start cursor-pointer [&>*]:cursor-pointer",
    {
      "aria-disabled:pointer-events-none cursor-default opacity-50":
        inputProps.disabled,
      "flex-row": labelOrder === "after",
      "flex-row-reverse justify-between": labelOrder === "before",
    },
  );

  const sharedProps = {
    "aria-disabled": inputProps.disabled,
    className: classes,
  };

  return href ? (
    <Link {...sharedProps} href={href}>
      <CheckboxBody {...inputProps} />
    </Link>
  ) : (
    <div {...sharedProps}>
      <CheckboxBody {...inputProps} />
    </div>
  );
};

export default Checkbox;
